input::placeholder {
    color: #868d9f !important;
}

.ant-form-item-label>label {
    font-weight: 500 !important;
}

.ant-input[disabled] {
    color: #222 !important;
    background-color: #fff !important;
}

.ant-input,
input.form-control {
    min-height: 35px !important;
    width: 100%;
    border-radius: 5px !important;
    border-color: #bdc3d4 !important;
}

.ant-input:focus {
    border-color: #4096ff !important;
}

.form-group {
    label {
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    input.form-control {
        padding: 4px 11px !important;

        &:focus {
            border-color: #4096ff !important;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
            border-inline-end-width: 1px !important;
            outline: 0 !important;
        }
    }

    label+input.form-control {
        margin-top: 8px;
    }
}



.ant-select-selector {
    min-height: 35px !important;
    width: 100%;
    border-radius: 5px !important;
    border-color: #bdc3d4 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff !important;
    color: #222 !important;
}

.ant-select-dropdown {
    z-index: 1080 !important;
}

.ant-select-selection-placeholder {
    color: #868d9f !important;
}

.ant-picker-dropdown {
    z-index: 1080 !important;
}

.ant-upload.ant-upload-drag {
    border-color: #009ef7 !important;
}

.ant-picker {
    min-height: 35px !important;
    border-radius: 5px !important;
    border-color: #bdc3d4 !important;
}

.ant-picker-disabled {
    background-color: #fff !important;
    color: #222 !important;
}

.iframeApp {
    width: 100%;
    height: calc(100vh - 140px);
}

.ant-table-thead>tr>th {
    background-color: #f3f2f7 !important;
    color: #40566f !important;
    font-weight: 600 !important;
    border: 0px !important;
}

.customList>.ant-list-header {
    background-color: #f3f2f7 !important;
    color: #40566f !important;
    font-weight: 600 !important;
}

.modal-header>button {
    padding: 0.5rem !important;
    border: 0px !important;
    background-color: transparent !important;
    color: #fff !important;
}

.text-header-td {
    color: #575962;
}

/* .ant-modal-wrap {
  z-index: 9999;
}
 */
.td-row-dashed {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--kt-border-color);
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.ant-modal-root {
    z-index: 99999;
}

.hop-mohinh {
    border: 1px solid #2196f3 !important;
    width: 10px;
    height: 25px;
    float: left;
    margin-right: 1px;
    background-color: #e6e6fa;
}

.td-mohinh {
    border: 1px solid #ff9800 !important;
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6;
}

.td-table-item:hover {
    background-color: #dee2e6 !important;
}

.text-accent {
    color: #00c5dc !important;
}

// Theme blue
#kt_app_header,
#kt_app_sidebar {
    background: -webkit-linear-gradient(60deg, #003790, #2d85c3) !important;
}

#kt_app_header {
    margin-bottom: 16px;
}

#kt_app_header_container {
    background-color: #174fc9 !important;
}

#kt_app_sidebar_logo {
    background-image: url('../../../../public/media/background/header.jpg');
}

#kt_app_header_wrapper {
    margin-left: -10px;
    margin-right: -10px;
    background-image: url('../../../../public/media/background/header.jpg');

    .app-navbar {
        margin-right: 6px;
    }
}

.menu-link {
    &:hover {
        background-color: #2879b4 !important;

        .menu-arrow::after {
            color: #fff !important;
        }

        .menu-bullet {
            color: #fff !important;
        }

        .menu-title {
            color: #f4f444 !important;
        }
    }

    .menu-title,
    .menu-arrow {
        color: #fff !important;
    }

    .menu-icon {
        .svg-icon {
            color: #fff !important;
        }
    }
}

.menu-link.active {
    background: none !important;

    .menu-title {
        color: #f4f444 !important;
    }
}

.menu-link.without-sub:hover {
    background-color: #2879b4 !important;
}

.menu-item.show .menu-sub {
    max-height: 400px;
    overflow-y: scroll;
}

.menu-item.show::-webkit-scrollbar {
    width: 8px;
}

.menu-item.show::-webkit-scrollbar-track {
    background-color: transparent;
}

.menu-item.show::-webkit-scrollbar-thumb {
    background-color: 3f95d5;
    height: 10px;
}

.ant-table-thead>tr>th,
.ant-table-thead>tr>td.ant-table-selection-column {
    background-color: #3f95d5 !important;
    color: #fff !important;
    font-weight: 700;
    border-right: 1px solid #ecd19a !important;
    border-top: 1px solid #ecd19a !important;
    border-bottom: 1px solid #ecd19a !important;
    text-align: center !important;
}

.ant-table-thead>tr>th:first-child {
    border-left: 1px solid #ecd19a !important;
}

// .ant-table-thead>tr>th:last {
//     border-right: 2px solid #ecd19a !important;
// }

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: 1px solid #bdbdbd !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:first-child {
    border-left: 1px solid #bdbdbd !important;
}

.ant-table-tbody>tr>td {
    border-bottom: 1px solid #bdbdbd !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 2px;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 2px;
}

.ant-dropdown-menu-item-only-child:hover {
    background-color: #e7f4fe !important;
}

.ant-input[disabled] {
    color: #222 !important;
    background-color: #e6e6e6 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #e6e6e6 !important;
    color: #222 !important;
}

:where(.css-dev-only-do-not-override-k7429z).ant-picker.ant-picker-disabled {
    background-color: #e6e6e6 !important;
    border-color: #d9d9d9;
    cursor: not-allowed;
}


.card .card-body {
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.05) !important;
}

.sun-editor-editable p {
    font-size: 16px !important;
}

.not-allowed {
    .sun-editor {
        // pointer-events: none ! important;
        // cursor: not-allowed ! important;

        .se-toolbar {
            // display: none;
        }

        .se-wrapper-inner {
            height: 450px;
            overflow-y: auto;
            background-color: #e6e6e6 !important;
        }
    }
}

.react-flow__panel.react-flow__attribution {
    display: none;
}

@media (min-width: 992px) {
    [data-kt-app-sidebar-hoverable=true] .app-sidebar .app-sidebar-wrapper {
        max-height: calc(100vh - 50px);
        overflow-y: auto;
    }
}

.multi-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0 !important;
}

// Antd tabs
.ant-tabs-nav-list .ant-tabs-tab {
    min-width: 100px;
    justify-content: center;
}
